import { Box, BoxProps, Grid, Stack, styled, Typography } from '@mui/material';
import React, { useState } from 'react';
import { NoPhoto } from '~components/icons';
import isUrl from 'is-url';
import { useDispatch } from 'react-redux';
import { RootDispatch } from '~stores';
import { Event } from '~types';
import moment from 'moment';

type Props = {
  isDragging: boolean;
  event: Event;
} & BoxProps;

const ArchivedVideoCardItem = ({ event, ...boxProps }: Props) => {
  const [error, setError] = useState(false);
  const dispatch = useDispatch<RootDispatch>();
  const onVideoClick = () => {
    dispatch.archivedVideos.setCurrentVideo(event);
  };
  const duration = moment
    .utc(
      Math.abs(
        moment(new Date(event.startedAt)).diff(
          new Date(event.endedAt),
          'milliseconds',
        ),
      ),
    )
    .format('HH:mm:ss');
  return (
    <Grid container maxWidth={500}>
      <Grid xs={8} sm={8} md={12} lg={12} xl={12} item>
        <Box {...boxProps} position="relative" onClick={onVideoClick}>
          <ThumbNailContainer style={{ cursor: 'pointer' }}>
            <Container borderRadius={5}>
              {isUrl(event.imageUrl) && !error ? (
                <img
                  style={{ objectFit: 'cover' }}
                  width="100%"
                  height="100%"
                  src={event.imageUrl}
                  alt="photo"
                  onError={() => setError(true)}
                />
              ) : (
                <NoPhoto />
              )}
            </Container>
            <OverlayContent>
              <Typography
                sx={{
                  paddingLeft: 1,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {moment(new Date(event.scheduledAt)).format('MM/DD/YYYY')}
              </Typography>
              <Typography
                sx={{
                  paddingLeft: 1,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {duration}
              </Typography>
            </OverlayContent>
          </ThumbNailContainer>
        </Box>
      </Grid>
      <Grid
        xs={4}
        sm={4}
        md={12}
        lg={12}
        xl={12}
        display={'flex'}
        justifyContent={'start'}
        alignItems={'center'}
        item
      >
        <Typography
          sx={{
            color: 'white',
            paddingLeft: 1,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {event.videoTitle}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ArchivedVideoCardItem;

const ThumbNailContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    width: 250,
  },
  height: 150,
  overflow: 'hidden',
}));

const Container = styled(Stack)(() => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  justifyContent: 'center',
  alignItems: 'center',
  background: '#434343',
}));

const OverlayContent = styled(Stack)(() => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 1,
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  padding: '4px 8px',
  borderRadius: '0px 0px 20px 20px',
}));
